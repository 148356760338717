body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: rgb(246, 246, 246);
	width: 100%;
	overflow-x: hidden;
}
.root {
	width: 100%;
}
button.slick-prev,
button.slick-next {
	border: solid rgb(20, 20, 20);
	border-radius: 12px;
	color:brown;
	background: black !important;
	height: 100px;
	width: 60px;
	z-index: 2;
}
button.slick-next {
	right: 2%;
	z-index: 2;
}
button.slick-prev {
	left: 2%;
}
button.slick-prev:hover{
		transform: scale(1.08) translate(0, -50%);
	background: rgb(78, 78, 78) !important;
}
button.slick-next:hover{
	transform: scale(1.08) translate(0, -50%);
	background: rgb(78, 78, 78) !important;
}

.nav {
	overflow: hidden;
}

code {
	font-family:  monospace;
}
@media screen and (max-width: 800px) {
	.footer {
		width: 100%;
	}

	h2.btn-txt {
		font-size: 1rem;
		padding: 2%;
	}
}
.hidden {
	display: none;
}
@font-face {
	font-family: "poppins";
	src: url(./Poppins-Regular.ttf);
}
@media screen  and (max-width:445px){
	 button.slick-prev,
button.slick-next {
	border: solid rgb(20, 20, 20);
	border-radius: 12px;
	color:brown;
	background: black !important;
	height: 40px;
	width: 40px;
	z-index: 2;
}
}