:root {
	--primary-color: #f6f6f6;
	--secondary-color: #1d1d1d;
}
@media screen and (max-width: 800px) {
	.video {
		display: none;
	}
}

.body {
	background: var(--primary-color);
	overflow: hidden;
	width: 1440px;
}
a:visited {
	text-decoration: none;
	color: black;
}
a {
	text-decoration: none;
}
ul {
	list-style: none;
	margin: 0 auto;
}

img.logo {
	min-height: 60px;
	min-width: 200px;
	position: relative;
	top: 4px;
	margin-top: 28%;
	left: 0.8%;
	height: 3%;
	width: 3%;
	border-radius: 8px;
	margin: 0.8em;
}

.intro {
	font-family: "Poppins";
	background: var(--secondary-color);
	color: var(--primary-color);
	width: 100%;
	height: 40%;
	grid: 40% 5% 30%;
	column-gap: 10%;
	display: inline-flex;
	overflow: hidden;
}
.desc {
	font-family: "Poppins";
	inline-size: 40%;
	margin-left: 2%;
	overflow: hidden;
	font-size: 136%;
	text-align: left;
}
.foto {
	position: absolute;
	width: 50%;
	height: 93%;
	margin-left: 50%;

	padding: 0;
}
.title {
	background: none;
	font-family: "Poppins";
	margin-left: 2.4%;
	color: var(--secondary-color);
	width: 100%;
	text-transform: uppercase;
}
.form
{
	display: grid;
}
.photo {
	
	
	z-index: -1;
	object-fit: contain;
}
.dot {
	list-style: square;
	display: inline-flex;
	width: 400px;
	height: 10px;
}



.showroom {
	align-items: center;
}
img.showroomphoto {
	position: absolute;
	z-index: -1;

	top: 0%;
	height: 100%;
	width: 100%;
}
.carosellotitolo {
	text-transform: uppercase;
	text-align: center;
	font-size: 226%;
}
.input {
display: inline;
	margin-top: 0%;
  text-align: left;
	position: relative;
	left:6%;
	width: 100%;
}
.style {
	background: none;
	color: #1d1d1d;
	border:none;
	margin: 20px;
	inline-size: 40%;
	height:32px;

	font-family: "Poppins";
	font-size: 16pt;
	border-bottom: solid 0.08em #959397;
}
::placeholder
{
	color:#959397;
	display: flex;
	font-size: 1.2rem;
}
.name {
	padding: 0.6%;
}
.txt {
	
	font-family: "poppins";
}
.text {
	margin-bottom: 0;
	margin-top: 0;
	margin-left: 0%;
}
.messaggio {
	width: 50%;
	margin-top: 2.4%;
}
.container{
	text-align: center;
	height: 900px;
}
.btn-container {
	position: relative;
	left:4%;
	width: 400px;
	margin-top: 2%;
}
.btn-txt {
	margin: 0;
	position: relative;
	padding: 0%;
	font-size: 1.2rem;
}
.submit {
	background-color: #f6f6f6;
	color: #1d1d1d;
	border: #1d1d1d solid;
	padding: 2%;
	width: 100%;
	height: 60px;
	position: relative;
	
	left: 82%;
	margin-top: 2%;
	border-radius: 12px;
}
.submit:active {
	color: #f6f6f6;
	background-color: black;
	border: #1d1d1d solid;
}
.icona {
	height: 100%;
	margin: 1.8%;
	float: left;
}

.address {
	height: 100%;
	overflow: show;
	display: inline-flex;
	width: 100%;
}
.indirizzo {
	margin-top: 2em;
	vertical-align: center;
	margin-left: 4%;
	width: 22%;
	height: 20px;
	inset-inline: 300px;
}
.conte {
	margin-bottom: 8em;
	margin-right: 8%;
	margin-left: 2%;
}
.indi {
	white-space: nowrap;
	margin-right: 2em;
}
img.social {
	position: relative;
	margin: 2%;
	padding: 2%;
	top: 10%;
	left: 8%;
}
@media screen and (max-width:800px) {
	.icona {
	height: 1 0px;
	margin: 1.8%;
	float: left;
}
.text
{inline-size: 76%;
text-align: center;}
.formtitle
{font-size:1.2rem;}
.formdesc
{font-size: 0.8rem;;}
.container{
	position: relative;
	top:32px;
	left:48px;
	display: grid;
	
}

.style
{
	height: 28px;
}
.btn-container
{
	height:40px;
}
.submit
{
	height: 22px;
	padding:8%;
}
.btn-txt
{
	margin:0;
	padding:6%;
}
}